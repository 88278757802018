import Vue from 'vue/dist/vue.js'
import { headerItem } from '@/utils/table'
import { formatNumber, formatDate } from '@/utils/string/index.js'

export default function headers () {
  return [
    headerItem('Date', 'date', {
      cellClass: 'font-bold sticky left-0',
      generator: (item) => {
        return { value: formatDate(item.tweetAt) }
      },
    }),
    headerItem('Twitter', 'twitter', {
      cellClass: 'font-bold',
      generator: (item) => {
        return {
          component: Vue.component('tw-link', {
            props: ['href', 'text'],
            template: '<a :href="href" target="_blank">{{ text }}</a>',
          }),
          props: {
            href: item.url,
            text: item.channel,
          },
        }
      },
    }),
    headerItem('BTC Price', 'price', {
      generator: (item) => {
        return { value: formatNumber(item.price) }
      },
    }),
    headerItem('Content', 'content', {
      generator: (item) => {
        const _class = item.aiMessage ? 'tweet-content-2line' : 'tweet-content'
        return {
          component: Vue.component('tw-link', {
            props: ['text', 'aiMessage'],
            template: `
              <div class="${_class} overflow-scroll">
                <span class="break-normal">{{ text }}</span>
                <template v-if="aiMessage">
                  <br/>
                  <b>AI:</b>
                  <span class="text-xs break-words">{{ aiMessage }}</span>
                </template>
              </div>
            `,
          }),
          props: {
            aiMessage: item.aiMessage,
            text: item.text,
          },
        }
      },
    }),
  ]
}
