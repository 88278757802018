<template>
  <div class="flex flex-col overflow-x-scroll">
    <d-paging
      :page="page"
      :total="totalPage"
      size="xs"
      v-model="page"
      class="ml-auto my-4"
    />
    <d-table
      :headers="headers"
      :items="tweets"
      :row-click="rowClick"
      row-classes="cursor-pointer"
      compact
    />
    <d-paging
      :page="page"
      :total="totalPage"
      size="xs"
      v-model="page"
      class="ml-auto"
    />
  </div>
</template>

<script>
import { buildQuery } from '@/utils/string/index.js'
import headerGenerator from './headers.js'

const LIMIT = 15

export default {
  name: 'twitter',

  meta: {
    title: 'Twitter',
  },

  data: () => ({
    headers: headerGenerator(),
    tweets: [],
    page: 1,
    totalPage: 0,
  }),

  created() {
    this.fetch()
  },

  methods: {
    rowClick($event, item) {
      window.open(item.url)
    },
    async fetch() {
      const query = {
        limit: LIMIT,
        page: this.page,
        sort: {
          _id: -1,
        },
        filter: {
          channel: { $nin: ['fake'] },
        },
        isPaging: true,
      }
      const { total, docs } = await this.$http.get(
        `/api/v2/public/Tweet?${buildQuery(query)}`
      )
      this.totalPage = Math.ceil(total / LIMIT)
      this.tweets = docs
    },
  },

  watch: {
    page() {
      this.fetch()
    },
  },
}
</script>
